import { LinkProps } from 'next/link';
import { createContext, ReactNode, useContext } from 'react';

type PublicPageContextProps = {
  getCaseStudyPageLink: (
    slug: string,
    collectiveSelection?: boolean
  ) => string | LinkProps['href'];
  getPublicPageLink: (slug: string) => string | LinkProps['href'];
};
export const PublicPageContext = createContext<PublicPageContextProps>({
  getCaseStudyPageLink: () => '',
  getPublicPageLink: () => '',
});

type PublicPageContextProviderProps = {
  children: ReactNode;
  getCaseStudyPageLink?: (slug: string) => string | LinkProps['href'];
  getPublicPageLink?: (slug: string) => string | LinkProps['href'];
};

export const PublicPageContextProvider = ({
  children,
  getCaseStudyPageLink = () => '',
  getPublicPageLink = () => '',
}: PublicPageContextProviderProps) => {
  const contextValue = { getCaseStudyPageLink, getPublicPageLink };

  return (
    <PublicPageContext.Provider value={contextValue}>
      {children}
    </PublicPageContext.Provider>
  );
};

export const usePublicPage = () => useContext(PublicPageContext);
