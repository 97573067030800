import { Box, Button, IconUsers } from '@collective/ui';
import { tallyForms } from '@collective/utils/shared';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

type JoinCollectiveProps = {
  collectiveSlug: string;
};

export const JoinCollective = ({ collectiveSlug }: JoinCollectiveProps) => {
  const { t } = useTranslation();
  const { query } = useRouter();

  const language = (query.language || 'fr') as 'en' | 'fr';

  return (
    <>
      <Box textAlign="right">
        <Button
          variant="third"
          leftIcon={<IconUsers size="sm" />}
          data-collective={collectiveSlug}
          data-tally-layout="modal"
          data-tally-width="600"
          data-tally-hide-title="1"
          data-tally-open={tallyForms[language].joinCollective}
          data-tally-emoji-text="👋"
          data-tally-emoji-animation="wave"
        >
          {t('collectivePage.joinCollective')}
        </Button>
      </Box>

      <Head>
        <script async src="https://tally.so/widgets/embed.js" />
      </Head>
    </>
  );
};
