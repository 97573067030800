import { IconArrowNarrowDown } from '@collective/ui';
import { Button, ButtonProps } from '@collective/ui/lib/button';
import { useTranslation } from 'react-i18next';

type UnfoldSectionButtonProps = ButtonProps & {
  isOpen: boolean;
};

export const UnfoldSectionButton = ({
  isOpen,
  ...rest
}: UnfoldSectionButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="third"
      w="100%"
      mt={4}
      borderY="1px solid"
      borderX="none"
      borderRadius={0}
      borderColor="rythm.200"
      rightIcon={
        <IconArrowNarrowDown
          size="2xs"
          transition="transform .3s"
          {...(isOpen && { transform: 'rotate(180deg)' })}
        />
      }
      {...rest}
    >
      {isOpen
        ? t('collectivePage.unfoldSectionButton.hide')
        : t('collectivePage.unfoldSectionButton.show')}
    </Button>
  );
};
