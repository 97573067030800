import { Button, ButtonProps } from '@collective/ui/lib/button';
import Link from 'next/link';
import { useRouter } from 'next/router';

import * as routes from '~/public-pages/constants/routes';
import { getIsMarketplacePage } from '~/public-pages/utils/urls';

type ContactCollectiveButtonProps = ButtonProps & {
  children: React.ReactNode;
};

export const ContactCollectiveButton = (
  props: ContactCollectiveButtonProps
) => {
  const { pathname, query } = useRouter();

  const isMarketplacePage = getIsMarketplacePage(pathname);

  const contactPageHref = isMarketplacePage
    ? {
        pathname: routes.MARKETPLACE_CONTACT_PATH,
        query,
      }
    : { pathname: routes.CONTACT_PATH };

  return (
    <Link href={contactPageHref} passHref>
      <Button borderRadius="84px" {...props} />
    </Link>
  );
};
