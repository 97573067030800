import { COLLECTIVE_WEBSITE_URL } from '@collective/utils/helpers/lib/constants';
import { getNextLogger } from '@collective/utils/next-logger';
import { resolveHref } from 'next/dist/shared/lib/router/router';
import Router from 'next/router';
import { Redirect } from 'next/types';
import { UrlObject } from 'url';

import * as routes from './constants/routes';

function getSearchParams(collectiveSelection?: boolean) {
  return collectiveSelection ? { collectiveSelection: true } : {};
}

export function toCollectiveRoot(collectiveSelection?: boolean) {
  return {
    pathname: routes.ROOT,
    query: getSearchParams(collectiveSelection),
  };
}

export function toCollectiveCaseStudy(
  caseStudySlug: string,
  collectiveSelection?: boolean
) {
  return {
    pathname: routes.CASE_STUDY_PATH,
    query: {
      caseStudySlug,
      ...getSearchParams(collectiveSelection),
    },
  };
}

export function collectiveNotFoundRedirect(collectiveSlug?: string): {
  redirect: Redirect;
} {
  getNextLogger().info(
    `No collective found for slug ${collectiveSlug}, redirecting to ${COLLECTIVE_WEBSITE_URL}`
  );
  return {
    redirect: {
      permanent: false,
      destination: COLLECTIVE_WEBSITE_URL,
    },
  };
}

export function projectNotFoundRedirect(projectSlug?: string): {
  redirect: Redirect;
} {
  getNextLogger().info(
    `Project was not found for slug ${projectSlug}, redirecting to ${COLLECTIVE_WEBSITE_URL}`
  );
  return {
    redirect: {
      permanent: false,
      destination: COLLECTIVE_WEBSITE_URL,
    },
  };
}

export function collectiveCaseStudyNotFoundRedirect(
  collectiveSlug?: string,
  caseStudySlug?: string
): {
  redirect: Redirect;
} {
  getNextLogger().info(
    `No case study found for collective slug ${collectiveSlug} and case study slug ${caseStudySlug}, redirecting to ${toCollectiveRoot()}`
  );
  return {
    redirect: {
      permanent: false,
      destination: formatUrl(toCollectiveRoot()),
    },
  };
}

// Formatting urls using Next.js syntax as described in https://github.com/vercel/next.js/discussions/22025
export function formatUrl(urlObject: UrlObject) {
  const [, resolvedAs] = resolveHref(Router, urlObject, true);
  return resolvedAs as string;
}
