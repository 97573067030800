import {
  PublicPages_MemberFragment,
  PublicPages_MemberInvitedFragment,
} from '@collective/data-type';
import { Collapse, IconMessage } from '@collective/ui';
import { Avatar } from '@collective/ui/lib/avatar';
import { Button } from '@collective/ui/lib/button';
import {
  BorderBox,
  BoxProps,
  Flex,
  SimpleGrid,
  SimpleGridProps,
} from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography';
import { publicPageAvatarImageParams } from '@collective/ui-smart';
import { addSearchParams } from '@collective/utils/helpers';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JoinCollective } from '~/public-pages/components/collective-page/join-collective';
import { ContactCollectiveButton } from '~/public-pages/components/contact/contact-collective-button';
import { useHasCollectiveSelection } from '~/public-pages/hooks/use-has-collective-selection';
import { getDataFromRealOrInvitedMember } from '~/public-pages/utils/member';
import { getIsMarketplacePage } from '~/public-pages/utils/urls';

type MembersSideCardProps = {
  collectiveSlug: string;
  allMembers: Array<
    PublicPages_MemberFragment | PublicPages_MemberInvitedFragment
  >;
} & BoxProps;

export const MembersSideCard = ({
  allMembers,
  collectiveSlug,
  ...rest
}: MembersSideCardProps) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const [showAllMembers, setShowAllMembers] = useState(false);
  const isMarketplacePage = getIsMarketplacePage(pathname);
  const hasCollectiveSelection = useHasCollectiveSelection();

  const alwaysShownMembers = allMembers.slice(0, 10);
  const hiddenMembers = allMembers.slice(10);

  return (
    <Flex flexDirection="column" {...rest}>
      <BorderBox noHover w="354px" p="20px" color="rythm.900" mb={4}>
        {allMembers.length ? (
          <Flex align="center" justify="space-between" minH="36px">
            <Text variant="desktop-s-semibold">
              {t('collectivePage.membersSideCard.count', {
                count: allMembers.length,
              })}
            </Text>
            {hiddenMembers.length > 0 && (
              <Button
                variant="third"
                onClick={() => setShowAllMembers((cur) => !cur)}
              >
                {showAllMembers
                  ? t('collectivePage.membersSideCard.hide')
                  : t('collectivePage.membersSideCard.seeAll')}
              </Button>
            )}
          </Flex>
        ) : (
          <Text variant="desktop-s-semibold" mt={2} mb={6}>
            {t('collectivePage.membersSideCard.noPublicMembers')}
          </Text>
        )}
        {alwaysShownMembers.length > 0 && (
          <MemberGrid members={alwaysShownMembers} mt={4} />
        )}
        {hiddenMembers.length > 0 && (
          <Collapse in={showAllMembers}>
            <MemberGrid members={hiddenMembers} mt="8px" />
          </Collapse>
        )}
        {!hasCollectiveSelection && (
          <ContactCollectiveButton
            size="lg"
            w="100%"
            mt={4}
            rightIcon={<IconMessage />}
          >
            {isMarketplacePage
              ? t('collectivePage.contactButton.onMarketplace')
              : t('collectivePage.contactButton.onCollectivePage')}
          </ContactCollectiveButton>
        )}
      </BorderBox>
      {!hasCollectiveSelection && (
        <JoinCollective collectiveSlug={collectiveSlug} />
      )}
    </Flex>
  );
};

const MemberGrid = ({
  members,
  ...rest
}: {
  members: Array<
    PublicPages_MemberFragment | PublicPages_MemberInvitedFragment
  >;
} & SimpleGridProps) => {
  return (
    <SimpleGrid columns={5} gap="8px" {...rest}>
      {members.map((member) => {
        const { name, pictureUrl } = getDataFromRealOrInvitedMember(member);

        return (
          <Avatar
            key={member.id}
            size="xl"
            showBorder
            borderColor="rythm.900"
            src={addSearchParams(pictureUrl, publicPageAvatarImageParams)}
            name={name}
          />
        );
      })}
    </SimpleGrid>
  );
};
