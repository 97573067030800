import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useHasCollectiveSelection = (): boolean => {
  const router = useRouter();
  const [isCollectiveSelection, setIsCollectiveSelection] = useState(true);

  useEffect(() => {
    setIsCollectiveSelection(
      !router.isReady || router.query.collectiveSelection === 'true'
    );
  }, [router.isReady, router.query.collectiveSelection]);

  return isCollectiveSelection;
};
