import 'swiper/css';

import { PublicPages_CaseStudyFragment } from '@collective/data-type';
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconButton,
} from '@collective/ui';
import { Box, BoxProps, Flex } from '@collective/ui/lib/layout';
import { Heading } from '@collective/ui/lib/typography';
import { CaseStudyCard, useSessionStorage } from '@collective/ui-smart';
import { chunk } from 'lodash';
import Link from 'next/link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import SwiperClass, { Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { usePublicPage } from '~/public-pages/contexts/public-page-context';
import { useHasCollectiveSelection } from '~/public-pages/hooks/use-has-collective-selection';
import { formatUrl } from '~/public-pages/router';

type CaseStudiesSectionType = {
  slug: string;
  caseStudies: PublicPages_CaseStudyFragment[];
} & BoxProps;

const CASE_STUDIES_PER_SLIDE = 3;

export const CaseStudiesSection = ({
  slug,
  caseStudies,
  ...rest
}: CaseStudiesSectionType) => {
  const { t } = useTranslation();
  const { getCaseStudyPageLink } = usePublicPage();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  // A bit hacky solution where we are keeping indexes in two places.
  // After first render we are updating state with sessionStorage value and after each state change we are updating sessionStorage
  // It's due to SSR issue and server not knowing about session storage
  const key = `case_studies_page_${slug}`;
  const [storageActiveIndex, setStorageActiveIndex] = useSessionStorage(key, 0);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffectOnce(() => {
    setActiveIndex(storageActiveIndex);
  });

  useUpdateEffect(() => {
    setStorageActiveIndex(activeIndex);
  }, [activeIndex]);

  const collectiveSelection = useHasCollectiveSelection();

  if (!caseStudies.length) {
    return null;
  }

  const caseStudySlides = chunk(caseStudies, CASE_STUDIES_PER_SLIDE);

  return (
    <Box {...rest}>
      <Flex mb={4} align="center" justify="space-between">
        <Heading variant="desktop-l-bold">
          {t('collectivePage.caseStudiesSection.title')}
          <Box as="span" color="rythm.700">
            {' '}
            ({caseStudies.length})
          </Box>
        </Heading>
        {caseStudySlides.length > 1 && (
          <Box>
            <IconButton
              mr={2}
              icon={<IconArrowNarrowLeft />}
              variant="iconButton"
              aria-label={t(
                'collectivePage.caseStudiesSection.ariaLabelPrevious'
              )}
              isDisabled={activeIndex === 0}
              onClick={() => swiper?.slidePrev()}
            />
            <IconButton
              icon={<IconArrowNarrowRight />}
              variant="iconButton"
              aria-label={t('collectivePage.caseStudiesSection.ariaLabelNext')}
              isDisabled={activeIndex === caseStudySlides.length - 1}
              onClick={() => swiper?.slideNext()}
            />
          </Box>
        )}
      </Flex>

      <Swiper
        modules={[Controller]}
        onSwiper={setSwiper}
        spaceBetween={24}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        simulateTouch={false}
        initialSlide={storageActiveIndex}
      >
        {caseStudySlides.map((caseStudiesInSlide, index) => {
          return (
            <SwiperSlide key={`page-${index}`}>
              {({ isActive }) => (
                <Flex direction="column" gap={3}>
                  {caseStudiesInSlide.map((caseStudy) => {
                    const caseStudyPageLink = getCaseStudyPageLink(
                      caseStudy.slug || '',
                      collectiveSelection
                    );
                    // Using an ObjectUrl here causes storybook to error as it believes we are in a new "app" router. This is an easy fix to avoid that but hopefully there will be a better fix coming out
                    // https://github.com/vercel/next.js/issues/43769#issuecomment-1371647893
                    const caseStudyHref =
                      typeof caseStudyPageLink === 'string'
                        ? caseStudyPageLink
                        : formatUrl(caseStudyPageLink);

                    return (
                      <Link
                        key={caseStudy.id}
                        href={caseStudyHref}
                        style={{
                          display: 'block',
                          height: '100%',
                          outline: 'none',
                        }}
                        tabIndex={isActive ? 0 : -1}
                      >
                        <CaseStudyCard caseStudy={caseStudy} />
                      </Link>
                    );
                  })}
                </Flex>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
