import {
  PublicPages_MemberFragment,
  PublicPages_MemberInvitedFragment,
} from '@collective/data-type';
import { displayName } from '@collective/utils/shared';

export function getDataFromRealOrInvitedMember(
  member: PublicPages_MemberFragment | PublicPages_MemberInvitedFragment
) {
  return 'user' in member
    ? {
        pictureUrl: member.pictureUrl,
        name: displayName(member.user),
      }
    : // invited member ⬇
      { pictureUrl: member.pictureUrl, name: displayName(member) };
}
