import {
  PublicPages_MemberFragment,
  PublicPages_MemberInvitedFragment,
} from '@collective/data-type';
import { Avatar, Collapse, IconButton, IconChevronDown } from '@collective/ui';
import { BorderBox, Flex } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography';
import { publicPageAvatarImageParams } from '@collective/ui-smart';
import { addSearchParams } from '@collective/utils/helpers';
import { useTranslation } from 'react-i18next';

import { getDataFromRealOrInvitedMember } from '~/public-pages/utils/member';

type MemberCardProps = {
  member: PublicPages_MemberFragment | PublicPages_MemberInvitedFragment;
  isExpanded: boolean;
  onToggleExpanded: () => void;
};

export const MemberCard = ({
  member,
  isExpanded,
  onToggleExpanded,
}: MemberCardProps) => {
  const { t } = useTranslation();
  const { name, pictureUrl } = getDataFromRealOrInvitedMember(member);

  return (
    <BorderBox
      display="flex"
      flexDirection="column"
      p="20px"
      position="relative"
      cursor="pointer"
      onClick={() => {
        // prevent toggle if the user is selecting text
        if (window.getSelection()?.toString().length === 0) {
          onToggleExpanded();
        }
      }}
    >
      <Flex justify="space-between" align="start">
        <Avatar
          src={addSearchParams(pictureUrl, publicPageAvatarImageParams)}
          name={name}
          size="xl"
          showBorder
          borderColor="rythm.900"
        />
        <IconButton
          variant="naked"
          size="lg"
          aria-label={
            isExpanded
              ? t(
                  'collectivePage.membersSection.ariaLabelCardDetailsArrow.hide'
                )
              : t(
                  'collectivePage.membersSection.ariaLabelCardDetailsArrow.show'
                )
          }
          icon={
            <IconChevronDown
              color="rythm.600"
              transition="transform .3s"
              {...(isExpanded && { transform: 'rotate(180deg)' })}
            />
          }
          onClick={(e) => {
            e.stopPropagation();
            onToggleExpanded();
          }}
        />
      </Flex>
      <Text variant="desktop-m-bold" mt={2} mb={1}>
        {name}
      </Text>
      <Text {...(!isExpanded && { noOfLines: 1 })}>{member.title}</Text>

      <Collapse in={isExpanded} speed="fast">
        <Text noOfLines={5} mt={1}>
          {member.bio}
        </Text>
      </Collapse>
    </BorderBox>
  );
};
