import {
  PublicPages_MemberFragment,
  PublicPages_MemberInvitedFragment,
} from '@collective/data-type';
import {
  BoxProps,
  Button,
  Collapse,
  IconEyeDisplayed,
  IconEyeHidden,
  useBreakpointValue,
} from '@collective/ui';
import {
  Box,
  Flex,
  SimpleGrid,
  SimpleGridProps,
} from '@collective/ui/lib/layout';
import { Heading } from '@collective/ui/lib/typography';
import { fill } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MemberCard } from './members-section-card';
import { UnfoldSectionButton } from './unfold-section-button';

type MembersSectionProps = {
  allMembers: Array<
    PublicPages_MemberFragment | PublicPages_MemberInvitedFragment
  >;
} & BoxProps;

const GRID_SPACING = '8px';

export const MembersSection = ({
  allMembers,
  ...rest
}: MembersSectionProps) => {
  const { t } = useTranslation();
  const alwaysShownMembersCount = useBreakpointValue({ base: 3, sm: 6 }) || 3;
  const [areAllMembersShown, setAreAllMembersShown] = useState(false);
  const [expandedMembers, setExpandedMembers] = useState(
    new Array(allMembers.length).fill(false)
  );

  if (!allMembers.length) {
    return null;
  }

  const alwaysShownMembers = allMembers.slice(0, alwaysShownMembersCount);
  const hiddenMembers = allMembers.slice(alwaysShownMembersCount);

  const onToggleExpanded = (index: number) => {
    setExpandedMembers((current) => {
      const updated = [...current];
      updated[index] = !updated[index];
      return updated;
    });
  };
  const areAllCardsExpanded = expandedMembers.every(Boolean);
  const onToggleAllExpanded = () =>
    setExpandedMembers((cur) => fill([...cur], !areAllCardsExpanded));

  return (
    <Box {...rest}>
      <Flex mb={4} align="center" justify="space-between">
        <Heading variant="desktop-l-bold">
          {t('collectivePage.membersSection.title')}
          <Box as="span" color="rythm.700">
            {' '}
            ({allMembers.length})
          </Box>
        </Heading>
        <Button
          variant="third"
          rightIcon={
            areAllCardsExpanded ? <IconEyeHidden /> : <IconEyeDisplayed />
          }
          onClick={onToggleAllExpanded}
        >
          {areAllCardsExpanded
            ? t('collectivePage.membersSection.showDetailsButton.hide')
            : t('collectivePage.membersSection.showDetailsButton.show')}
        </Button>
      </Flex>

      <MembersGrid
        members={alwaysShownMembers}
        expandedMembers={expandedMembers}
        memberIndexOffset={0}
        onToggleExpanded={onToggleExpanded}
      />
      <Collapse in={areAllMembersShown}>
        <MembersGrid
          members={hiddenMembers}
          expandedMembers={expandedMembers}
          memberIndexOffset={alwaysShownMembersCount}
          onToggleExpanded={onToggleExpanded}
          mt={GRID_SPACING}
        />
      </Collapse>

      {hiddenMembers.length > 0 && (
        <UnfoldSectionButton
          isOpen={areAllMembersShown}
          onClick={() => setAreAllMembersShown((cur) => !cur)}
        />
      )}
    </Box>
  );
};

type MembersGridProps = SimpleGridProps & {
  members: Array<
    PublicPages_MemberFragment | PublicPages_MemberInvitedFragment
  >;
  expandedMembers: boolean[];
  memberIndexOffset: number;
  onToggleExpanded: (index: number) => void;
};

const MembersGrid = ({
  members,
  expandedMembers,
  memberIndexOffset,
  onToggleExpanded,
  ...rest
}: MembersGridProps) => {
  return (
    <SimpleGrid
      columns={{ base: 1, sm: 2 }}
      alignItems="start"
      gap={GRID_SPACING}
      {...rest}
    >
      {members.map((member, index) => (
        <MemberCard
          key={member.id}
          member={member}
          isExpanded={expandedMembers[memberIndexOffset + index]}
          onToggleExpanded={() => onToggleExpanded(memberIndexOffset + index)}
        />
      ))}
    </SimpleGrid>
  );
};
