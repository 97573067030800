import { PublicPages_OfferedServiceFragment } from '@collective/data-type';
import { Collapse, useBreakpointValue } from '@collective/ui';
import {
  BorderBox,
  Box,
  BoxProps,
  SimpleGrid,
  SimpleGridProps,
} from '@collective/ui/lib/layout';
import { Heading, Text } from '@collective/ui/lib/typography';
import { servicesIcons } from '@collective/ui-smart';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UnfoldSectionButton } from './unfold-section-button';

type ServicesSectionProps = BoxProps & {
  services: PublicPages_OfferedServiceFragment[];
};

const GRID_SPACING = '8px';

export const ServicesSection = ({
  services,
  ...rest
}: ServicesSectionProps) => {
  const { t } = useTranslation();
  const [areAllServicesShown, setAreAllServicesShown] = useState(false);
  const alwaysShownServicesCount = useBreakpointValue({ base: 3, sm: 4 });

  if (!services.length) {
    return null;
  }

  const alwaysShownServices = services.slice(0, alwaysShownServicesCount);
  const hiddenServices = services.slice(alwaysShownServicesCount);

  return (
    <Box {...rest}>
      <Heading variant="desktop-l-bold" mb={4}>
        {t('collectivePage.servicesSectionTitle')}
        <Box as="span" color="rythm.700">
          {' '}
          ({services.length})
        </Box>
      </Heading>

      <ServicesGrid services={alwaysShownServices} />
      <Collapse in={areAllServicesShown}>
        <ServicesGrid services={hiddenServices} mt={GRID_SPACING} />
      </Collapse>

      {hiddenServices.length > 0 && (
        <UnfoldSectionButton
          isOpen={areAllServicesShown}
          onClick={() => setAreAllServicesShown((cur) => !cur)}
        />
      )}
    </Box>
  );
};

type ServicesGridProps = SimpleGridProps & {
  services: PublicPages_OfferedServiceFragment[];
};

const ServicesGrid = ({ services, ...rest }: ServicesGridProps) => {
  return (
    <SimpleGrid columns={{ base: 1, sm: 2 }} gap={GRID_SPACING} {...rest}>
      {services.map((service) => (
        <BorderBox key={service.id} noHover p="20px">
          {servicesIcons[service.icon]}
          <Text variant="desktop-m-bold" mt={1}>
            {service.title}
          </Text>
          <Text mt={2}>{service.description}</Text>
        </BorderBox>
      ))}
    </SimpleGrid>
  );
};
