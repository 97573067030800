import { BoxProps } from '@collective/ui/lib/layout';
import { Box } from '@collective/ui/lib/layout/box';
import { ImageWrapped } from '@collective/ui-smart';
import { PUBLIC_PAGE_COVER_RATIO } from '@collective/utils/helpers';
import { DEFAULT_BACKGROUND } from '@collective/utils/shared';

type CollectiveBannerProps = {
  coverUrl?: string | null;
} & BoxProps;

export const CollectiveBanner = ({
  coverUrl,
  ...rest
}: CollectiveBannerProps) => {
  const { bgProps, hasUrl } = getBannerProperty(coverUrl);

  /* The cover can't be an image since we can use gradient properties for random covers by default */
  return (
    <Box
      sx={{ aspectRatio: String(PUBLIC_PAGE_COVER_RATIO) }}
      w="100%"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      data-chromatic="ignore"
      {...bgProps}
      {...rest}
    >
      {hasUrl && coverUrl && (
        <ImageWrapped
          src={coverUrl}
          // on lg and up we use the original image so as to avoid the resizing, speed ends up being faster than resizing to a slightly smaller image
          srcSetSizes={['xs', 'sm', 'md', ['lg', 'original']]}
          w="100%"
          h="100%"
        />
      )}
    </Box>
  );
};

function getBannerProperty(coverUrl?: string | null) {
  /**
   * We had errors when using bgImage for the cover when sometimes
   * Next was trying to load it as an actual image but it was a css gradient.
   * Thanks to chakra and its 2 different properties, the gradient has its
   * own way to load.
   */
  if (coverUrl?.startsWith('http')) {
    return {
      hasUrl: true,
    };
  } else if (coverUrl) {
    return { bgProps: { bgGradient: coverUrl } };
  }

  return { bgProps: { bgGradient: DEFAULT_BACKGROUND } };
}
