import * as routes from '~/public-pages/constants/routes';
import { formatUrl } from '~/public-pages/router';

export const getCollectivePageUrl = (slug: string): string =>
  `${slug}.collective.work`;

export const getCaseStudyUrl = (
  collectiveSlug: string,
  caseStudySlug: string
): string =>
  `${getCollectivePageUrl(collectiveSlug)}${formatUrl({
    pathname: routes.CASE_STUDY_PATH,
    query: { caseStudySlug },
  })}`;

export const getIsMarketplacePage = (pathname: string | undefined) =>
  pathname?.startsWith('/marketplace/');
